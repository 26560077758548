<template lang="pug">
v-container(fluid)
  v-row(style='height: 80vh')
    v-col.d-flex.align-center.justify-center.flex-column(cols='12')
      div {{ $lang.empty.text1 }}
      div {{ msg }}
</template>

<script>
export default {
  name: 'EmptyEntities',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    msg() {
      return this.message.length ? this.message : this.$lang.empty.text2
    },
  },
}
</script>

<style scoped></style>
